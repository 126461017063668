<template>
    <div>
        <div class="nav">
            <div class="nav-top">
                <img :src="navTitleSrc" class="navTitle" alt="">
            </div>

            <div class="nav-add">
                <img :src="addSrc" alt="">
                <div>添加</div>
            </div>

            <ul class="nav-ul">
                <li @click="navIndex = index" :class="{ 'selectNav': navIndex == index }" v-for="(item, index) in menu"
                    :key="index">

                    <router-link :to="item.link">
                        <img :src="item?.icon" alt="">
                        <div>{{ item?.text }}</div>

                    </router-link>
                </li>

            </ul>

            <div class="nav-line"></div>

            <div class="nav-team">
                <div>团队空间</div>
                <img :src="folderSrc" alt="">
            </div>

            <img class="nav-icon" :src="iconSrc" alt="">

        </div>

        <div class="user">
            <img :src="headSrc" alt="">
            <div>{{ loginName }}</div>
        </div>

    </div>


</template>

<script>

export default {
    name: 'MainNavbar',
    data() {
        return {
            headSrc: require("../../../assets/head.png"),
            navTitleSrc: require('../../../assets/nav/navTitle.png'),
            addSrc: require('../../../assets/nav/add.png'),
            folderSrc: require('../../../assets/nav/folder-add.png'),
            iconSrc: require('../../../assets/nav/zhongdiao.png'),
            loginName: sessionStorage.getItem("loggedIn"),
            menu: [
                {
                    icon: require('../../../assets/nav/home_ui.png'),
                    text: "首页",
                    link: "/dashboard"
                    // link: { name: 'dashboard' }
                },
                {
                    icon: require('../../../assets/nav/ai_ui.png'),
                    text: "AI助手",
                    link: "/aiAssistant"
                },
                {
                    icon: require('../../../assets/nav/knowledge_ui.png'),
                    text: "知识库问答",
                    link: "/knowledge"
                },
                // {
                //     icon: require('../../../assets/nav/ppt_ui.png'),
                //     text: "PPT大师",
                //     link: "/ppt"
                // },
                // {
                //     icon: require('../../../assets/nav/poster_ui.png'),
                //     text: "AI海报",
                //     link: "/AIPoster"
                // },
            ],
            navIndex: 0
        }
    },
    mounted() {
        let that = this
        let index = this.menu.findIndex(function (item) {
            if (typeof item.link === 'string') {
                return item?.link.includes(that.$route.path)
            } else if (typeof item.link === 'object' && item.link.name) {
                return item.link.name === that.$route.name;
            }
            return false;
        });
        this.navIndex = index || 0

    },
    components: {

    }
}
</script>

<style scoped>
.nav {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #E6EBF2;
}

.nav-top {
    width: 252px;
    margin-top: 28px;
}

.navTitle {
    width: 160px;
    height: 28px;
}

.nav-add {
    width: 252px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #053C7E;
    border-radius: 8px;
    margin-top: 27px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    letter-spacing: -0.009999999776482582px;
    text-align: center;
}

.nav-add img {
    width: 13px;
    height: 13px;
    margin-right: 5px;
}

.nav-ul {
    list-style: none;
    width: 252px;
    margin: 20px 0 0 0;
    padding: 0;
}

.nav-ul li,
.nav-ul li a {
    width: 252px;
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 8px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #053C7E;
    border: 1px solid #E6EBF2;
    margin-bottom: 3px;
}

.nav-ul li a {
    background: none;
    border: none;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.nav-ul li:hover {
    border: 1px solid #053C7E;
}

.nav-ul li img {
    width: 16px;
    height: 16px;
    margin: 0 10px 0 15px;
}

.selectNav {
    background: #fff;
    border: 1px solid #fff;
}

.nav-line {
    background: #CDD8E5;
    width: 252px;
    height: 1px;
    margin: 20px 0 30px 0;
}

.nav-team {
    width: 252px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #053C7E;
}

.nav-team img {
    width: 18px;
    height: 18px;
}

.nav-icon {
    width: 252px;
    height: 38px;
    margin: 10px auto;
    /* margin: -5px auto; */
}

.user {
    position: fixed;
    left: 24px;
    bottom: 30px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #053C7E;
    display: flex;
    align-items: center;
}

.user img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
 
}
</style>