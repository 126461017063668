<template>
    <div class="main">
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
    </div>

</template>

<script>

export default {
    name: 'AppMain',
    data() {
        return {
         
        }
    },
    components: {

    }
}
</script>

<style>


.main {
    margin-left: 300px;
}
</style>