<template>
   <div class="app-wrapper">
    <div class="main-container">
      <navbar></navbar>
      <app-main></app-main>
    </div>
  </div>



</template>

<script>

import { Navbar, AppMain } from './components'

export default {
    name: 'app',
    data() {
        return {
        }
    },
    components: {
Navbar, AppMain
    }
}
</script>

<style>
.nav {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #E6EBF2;
}

.nav-top {
    width: 252px;
    margin-top: 28px;
}

.navTitle {
    width: 160px;
    height: 28px;
}

.nav-add {
    width: 252px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #053C7E;
    border-radius: 8px;
    margin-top: 27px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    letter-spacing: -0.009999999776482582px;
    text-align: center;
}

.nav-add img {
    width: 13px;
    height: 13px;
    margin-right: 5px;
}

.nav-ul {
    list-style: none;
    width: 252px;
    margin: 20px 0 0 0;
    padding: 0;
}

.nav-ul li {
    width: 252px;
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 8px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #053C7E;
    border: 1px solid #E6EBF2;
    margin-bottom: 3px;
}

.nav-ul li:hover {
    border: 1px solid #053C7E;
}

.nav-ul li img {
    width: 16px;
    height: 16px;
    margin: 0 10px 0 15px;
}

.selectNav {
    background: #fff;
    border: 1px solid #fff;
}

.nav-line {
    background: #CDD8E5;
    width: 252px;
    height: 1px;
    margin: 20px 0 30px 0;
}

.nav-team {
    width: 252px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #053C7E;
}

.nav-team img {
    width: 18px;
    height: 18px;
}

.nav-icon {
    width: 252px;
    height: 38px;
    margin: 10px auto;
    /* margin: -5px auto; */
}


.main {
    margin-left: 300px;
}
</style>