import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
import Layout from "@/views/layout/Layout"

var router = new Router({
    // mode: 'history',
    routes: [
        {
            path: "/login",
            name: "login",
            component: () => import("../views/login/index.vue"),
        },
        {
            path: "",
            component: Layout,
            redirect: "dashboard",
            children: [
                {
                    path: "dashboard",
                    component: () => import("@/views/dashboard/index"),
                    name: "dashboard",
                    meta: {
                        title: "首页",
                        icon: "dashboard",
                        noCache: true,
                    },
                },
                {
                    path: "aiAssistant",
                    component: () => import("@/views/aiAssistant/index"),
                    name: "aiAssistant",
                    meta: {
                        title: "AI助手",
                        icon: "dashboard",
                        noCache: true,
                    },
                },
                {
                    path: "knowledge",
                    component: () => import("@/views/knowledge/index"),
                    name: "knowledge",
                    meta: {
                        title: "知识库助手",
                        icon: "knowledge",
                        noCache: true,
                    },
                },
                {
                    path: "ppt",
                    component: () => import("@/views/ppt/index"),
                    name: "ppt",
                    meta: {
                        title: "PPT大师",
                        icon: "knowledge",
                        noCache: true,
                    },
                },
                {
                    path: "AIposter",
                    component: () => import("@/views/AIposter/index"),
                    name: "AIposter",
                    meta: {
                        title: "AI海报",
                        icon: "knowledge",
                        noCache: true,
                    },
                },
            ],
        },
    ],
})

// 导航守卫
router.beforeEach((to, from, next) => {
    // 检查用户是否已登录
    const loggedIn = sessionStorage.getItem("loggedIn");

    if (to.name === "login") {
        next();
        return;
    }

    if (!loggedIn) {
        next({ name: "login" });
        return;
    }

    next();
});


export default router
