import Vue from 'vue'
import App from './App.vue'
import router from './router/router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import 'normalize.css'

Vue.use(Toast);


Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')